import './index.css'
import React, {useState} from 'react'
import background from './assets/background.png'
import logo from './assets/logo.png'
import illustration from './assets/illustration.png'
import illustration2 from './assets/illustration2.png'
import instagram from './assets/icons/instagram.png'
import twitter from './assets/icons/twitter.png'
import facebook from './assets/icons/facebook.png'
import linkedin from './assets/icons/linkedin.png'
import jsonp from 'jsonp'
import { useTranslation } from 'react-i18next'
import MetaTags from 'react-meta-tags'


function App() {
  const [loadingState, setLoadingState] = useState(false)
  const [emailState, setEmailState] = useState('')
  const [messageState, setMessageState] = useState(null)
  const {t} = useTranslation()
  if (window.location.pathname !== "/fr" || window.location.pathname !== "") {
    window.history.pushState("", "", '/')
  }
  return (
    <div className="App" style={{backgroundImage: 'url(' + background + ')', backgroundSize: 'cover', height: '100vh'}}>
      <MetaTags>
        <meta name="description" content={t('meta_description')}/>
      </MetaTags>
      <header>
        <img onClick={() => {
          setEmailState('')
          setMessageState('')
        }} id='Logo' alt='Logo' src={logo}/>
        <button onClick={() => window.open(t('form_link'))} id='Beta'>
          {t('alpha')}
        </button>
      </header>
      <h1>
        {t('text1')}
        <span>{t('text2')}</span>
        {t('text3')}
        <span>{t('text4')}</span>
      </h1>
      <form onSubmit={(e) => {
        e.preventDefault()
        e.persist()
        if (emailState !== '') {
          setLoadingState(true)
          jsonp('https://noazet.us8.list-manage.com/subscribe/post-json?u=17551c3b66d3616b0234c7cb8&amp;id=0ba163c35f&EMAIL=' + emailState.replace('@', '%40') + '&c=__jp0', { param: 'c' }, (err, data) => {
            if (data.msg.includes('already')) {
              setMessageState(t('already'))
              setLoadingState(false)
              e.target.reset()
            } else if (err || data.result !== 'success') {
              setMessageState(t('error'))
              setLoadingState(false)
            } else {
              setMessageState(t('thanks'))
              setLoadingState(false)
              e.target.reset()
            }
          })
        } else {
          setMessageState(t('valid'))
          setLoadingState(false)
        } 
      }}>
        <label htmlFor='email'>
          {t('signup')}
        </label>
        <div>
          <input onChange={(event) => {
            setEmailState(event.target.value)
          }} name='email' type='email' placeholder={t('email')}/>
          <button disabled={loadingState} type='submit' id="Send">
            {t('send')}
          </button>
        </div>
        <span id='Response'>{messageState}</span>
      </form>
      <img id='Illustration' alt='Illustration' src={illustration}/>
      <img id='Illustration2' alt='Illustration2' src={illustration2}/>
      <div id='Icons'>
        <a href='https://instagram.com/Noazet_' target='_blank' rel="noopener noreferrer" id='Instagram'>
          <img alt='Instagram' src={instagram}/>
        </a>
        <a href='https://twitter.com/Noazet_' target='_blank' rel="noopener noreferrer" id='Twitter'>
          <img alt='Twitter' src={twitter}/>
        </a>
        <a href='https://www.facebook.com/Noazet/' target='_blank' rel="noopener noreferrer" id='Facebook'>
          <img alt='Facebook' src={facebook}/>
        </a>
        <a href='https://www.linkedin.com/company/noazet/' target='_blank' rel="noopener noreferrer" id='Linkedin'>
          <img alt='Linkedin' src={linkedin}/>
        </a>
      </div>
      <footer>
        Copyright &copy; 2021 Noazet
      </footer>
    </div>
  )
}

export default App
