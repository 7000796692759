import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import FR from './locales/fr.json'
import EN from './locales/en.json'

i18n
.use(XHR)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng'
    },
    resources: {
      fr: FR,
      en: EN
    },
    // lng: "en",
    fallbackLng: "en",
    supportedLngs: ['en', 'fr'],
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
})

if (i18n.language === 'fr') {
  window.history.pushState("", "", '/fr')
}

console.log(i18n.language)

export default i18n
